import React from "react";
import Container from "../components/container";
import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <Container>
      <Helmet>
        <title>Terms and Conditions - Festinekt</title>
        <meta
          name="description"
          content="These Terms and Conditions  govern your use of the Festinekt
          mobile application and related services . By accessing or using the Services, you agree to be bound
          by these Terms. If you do not agree to these Terms, please do not use
          the Services."
        />
      </Helmet>

      <div class="container mx-auto px-4 py-8 text-gray-300">
        <h1 class="text-3xl font-bold mb-4 text-orange-500">
          Terms and Conditions for Festinekt
        </h1>

        <p class="mb-4">
          These Terms and Conditions ("Terms") govern your use of the Festinekt
          mobile application and related services (collectively, the
          "Services"). By accessing or using the Services, you agree to be bound
          by these Terms. If you do not agree to these Terms, please do not use
          the Services.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          1. Use of Services
        </h2>

        <p class="mb-4">
          1.1 <strong>Eligibility:</strong> You must be at least 13 years old to
          use the Services. By using the Services, you represent and warrant
          that you are at least 13 years old.
        </p>

        <p class="mb-4">
          1.2 <strong>License:</strong> Subject to these Terms, we grant you a
          limited, non-exclusive, non-transferable, and revocable license to use
          the Services for your personal, non-commercial use.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">2. User Content</h2>

        <p class="mb-4">
          2.1 <strong>Submission:</strong> You may submit content, including but
          not limited to text, photos, and videos, through the Services ("User
          Content"). By submitting User Content, you grant us a worldwide,
          royalty-free, perpetual, irrevocable, and sublicensable right to use,
          reproduce, modify, adapt, publish, translate, distribute, and display
          such User Content in any media.
        </p>

        <p class="mb-4">
          2.2 <strong>Responsibility:</strong> You are solely responsible for
          the User Content you submit through the Services. You represent and
          warrant that you have all necessary rights and permissions to submit
          such User Content and that it does not violate any third-party rights
          or applicable laws.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          3. Prohibited Conduct
        </h2>

        <p class="mb-4">
          3.1 <strong>Restricted Activities:</strong> When using the Services,
          you agree not to engage in any of the following activities: violating
          any applicable laws or regulations, infringing any third-party rights,
          distributing spam or other unsolicited communications, interfering
          with the operation of the Services, or engaging in any other conduct
          that restricts or inhibits anyone's use or enjoyment of the Services.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          4. Intellectual Property
        </h2>

        <p class="mb-4">
          4.1 <strong>Ownership:</strong> All rights, title, and interest in and
          to the Services, including all intellectual property rights, are and
          will remain the exclusive property of Festinekt and its licensors.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          5. Disclaimer of Warranties
        </h2>

        <p class="mb-4">
          5.1 <strong>As Is:</strong> THE SERVICES ARE PROVIDED "AS IS" AND "AS
          AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          6. Limitation of Liability
        </h2>

        <p class="mb-4">
          6.1 <strong>Exclusion:</strong> TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, IN NO EVENT WILL FESTINEKT OR ITS AFFILIATES,
          OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS, OR SUPPLIERS BE
          LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
          PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA,
          USE, OR GOODWILL, ARISING FROM OR RELATED TO YOUR USE OF THE SERVICES.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">7. Governing Law</h2>

        <p class="mb-4">
          7.1 <strong>Jurisdiction:</strong> These Terms are governed by and
          construed in accordance with the laws of [Your Jurisdiction], without
          regard to its conflict of law principles.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">
          8. Changes to Terms
        </h2>

        <p class="mb-4">
          8.1 <strong>Modification:</strong> We reserve the right to modify
          these Terms at any time. If we make material changes, we will notify
          you by email or by posting a notice within the app. Your continued use
          of the Services after the effective date of any changes constitutes
          your acceptance of the updated Terms.
        </p>

        <h2 class="text-xl font-semibold mb-2 text-white">9. Contact Us</h2>

        <p class="mb-4">
          If you have any questions or concerns about these Terms, please
          contact us at: <br />
          <br /> Email:{" "}
          <a
            href="mailto:contact@festinekt.com"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            contact@festinekt.com
          </a>
          <br />
          Phone number:{" "}
          <a
            href="tel:+254742836404"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            +254742836404
          </a>
          <br />
          WhatsApp:{" "}
          <a
            href="https://wa.me/254742836404"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            +254742836404
          </a>
        </p>
      </div>
    </Container>
  );
}
