import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import { Link, useNavigate } from "react-router-dom";
import Label from "../components/label";
import TextInput from "../components/text-input";
import PrimaryButton from "../components/primary-button";
import ConfirmCodeModal from "../components/confirm-code-modal";
import axios from "axios";
import globals from "../globals/globals";
import toast, { Toaster } from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../context/app-context";
import { Helmet } from "react-helmet";

export default function Signup() {
  const { userData, setUserData } = useContext(AppContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [processing, setprocessing] = useState(false);
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  async function signup(e) {
    e.preventDefault();
    if (!firstName) {
      toast.error("First name is required");
    } else if (!lastName) {
      toast.error("Last name is required");
    } else if (!email) {
      toast.error("Email is required");
    } else if (!password) {
      toast("Password is required");
    } else {
      setprocessing(true);

      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const url = `${globals.ENDPOINT}/api/user/auth/signup`;
      const headers = {
        "auth-token": globals.TOKEN,
      };

      await axios
        .post(url, data, { headers })
        .then((response) => {
          setprocessing(false);

          if (response.data.status === "Success") {
            setModal(true);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setprocessing(false);

          toast.error(error.message);
        });
    }
  }

  async function verify(e) {
    e.preventDefault();
    if (!firstName) {
      toast.error("First name is required");
    } else if (!lastName) {
      toast.error("Last name is required");
    } else if (!email) {
      toast.error("Email is required");
    } else if (!password) {
      toast.error("Password is required");
    } else if (!code) {
      toast.error("OTP is required");
    } else {
      setprocessing(true);

      const data = {
        email,
        password,
        firstName,
        lastName,
        verificationCode: code,
      };

      const url = `${globals.ENDPOINT}/api/user/auth/verify-code`;
      const headers = {
        "auth-token": globals.TOKEN,
      };

      await axios
        .post(url, data, { headers })
        .then((response) => {
          setprocessing(false);

          if (response.data.status === "Success") {
            toast.success(response.data.message);

            setUserData(response.data.data);
            secureLocalStorage.setItem("userData", response.data.data);

            setFirstName("");
            setLastName("");
            setEmail("");
            setPassword("");
            setCode("");

            setModal(false);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setprocessing(false);

          toast.error(error.message);
        });
    }
  }

  return (
    <div className="flex">
      <Helmet>
        <title>Signup - Festinekt</title>
        <meta
          name="description"
          content="Stay ahead of the curve with Festinekt Blog's exclusive coverage of Nairobi's events, breaking news, and entertainment. Dive into the heart of Kenya's cultural scene, with in-depth articles on Nairobi events and the latest in Kenyan entertainment. Explore, engage, and stay informed with Festinekt Blog."
        />
      </Helmet>
      <Toaster />

      <div className="w-full md:w-[30%] bg-gray-900 min-h-screen p-10">
        <div className="flex items-center justify-center">
          <img
            src="/festinekt-for-dark.png"
            alt="Festinekt logo"
            className="object-contain w-[80%]"
          />
        </div>

        <Title className="text-[30px] mt-10 text-gray-300">
          Create an account
        </Title>

        <form onSubmit={signup} className="mt-10">
          <Label>First Name*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="e.g John"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />

          <Label myStyles="mt-4">Last Name*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="e.g Doe"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          <Label myStyles="mt-4">Email Address*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="e.g johndoe@gmail.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />

          <Label myStyles="mt-4">Password*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />

          <PrimaryButton processing={processing} className="w-full mt-4">
            {processing ? "Processing..." : "Create Account"}
          </PrimaryButton>
        </form>

        <p className="mt-4 text-center">
          Already have an account ?{" "}
          <Link className="text-myBlue font-bold" to={"/login"}>
            Login
          </Link>
        </p>
      </div>

      <div className="hidden w-[70%] bg-gray-800 p-10 md:flex items-center">
        <div className="w-[40%]">
          <Title className="text-[30px] mb-10 font-bold">
            Discover, Connect, Thrive
          </Title>
          <h1>
            We know you're looking for fun and connecting with mutuals that's
            why we present to you{" "}
            <span className="text-orange-500">Festinekt.</span> We bring all the
            fun and connections to one platform, making it easy for you to
            access it from anywhere around the world.
          </h1>

          <Link
            to="/about-festinekt"
            className="text-myBlue font-bold underline flex items-center gap-2"
          >
            Learn more
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
              />
            </svg>
          </Link>
        </div>

        <img
          src="/signup-image.png"
          alt="Festinekt login"
          className="w-[60%] h-full object-cover"
        />
      </div>

      {modal && (
        <ConfirmCodeModal
          email={email}
          code={code}
          setCode={setCode}
          setModal={setModal}
          verify={verify}
          processing={processing}
        />
      )}
    </div>
  );
}
