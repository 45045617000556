import React from "react";

export default function Unauthorized() {
  return (
    <div className="flex items-center justify-center h-screen" id="error-page">
      <div>
        <h1 className="font-bold text-center">Oops!</h1>
        <p className="text-center">Sorry, an unexpected error has occurred.</p>
        <p className="text-center">
          <i>You don't have permission to access this resource</i>
        </p>
      </div>
    </div>
  );
}
