import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/app-context";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import globals from "../globals/globals";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LoadingData from "./loading-data";

export default function Sidebar() {
  const { userData, setUserData, googleLogin, setGoogleLogin } =
    useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getCategories();
  }, []);

  async function auth() {
    if (userData) {
      setUserData(null);
      secureLocalStorage.clear();

      navigate("/");

      if (googleLogin) {
        googleLogout();
        setGoogleLogin(false);
      }
    } else {
      navigate("/login");
    }
  }

  const headers = {
    "auth-token": globals.TOKEN,
  };

  async function getCategories() {
    try {
      const response = await axios.get(
        `${globals.ENDPOINT}/api/blog/category`,
        { headers }
      );
      setLoadingData(false);
      if (response.data.status === "Success") {
        setCategories(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      toast.error("An error occured while getting categories");
    }
  }

  return (
    <div className="w-[20%] h-screen pt-[80px] bg-gray-900 fixed top-0 left-0 shadow-lg hidden lg:block">
      {loadingData && <LoadingData />}
      <Link
        to={`/`}
        className={`flex items-center h-[50px] gap-4 text-[14px] px-4 mt-8 text-gray-400 hover:bg-gray-800 hover:text-gray-100 hover:border-r-4 border-r-gray-700 ${
          location.pathname === "/" &&
          "text-white font-bold bg-gray-800 border-r-4 border-r-white"
        }`}
      >
        <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em">
          <path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z" />
          <path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z" />
        </svg>
        Home
      </Link>

      <div className="">
        {categories.map((item) => {
          const isActive = location.pathname.startsWith(
            `/blog/category/${item.slug}`
          );

          const linkStyles = isActive
            ? "text-white font-bold bg-gray-800 border-r-4 border-r-white"
            : "text-gray-400 hover:bg-gray-800 hover:text-gray-100 hover:border-r-4 border-r-gray-700";
          return (
            <Link
              key={item.name}
              state={item}
              to={`/blog/category/${item.slug}`}
              className={`flex items-center h-[50px] gap-4 text-[14px] px-4 ${linkStyles}`}
            >
              <div dangerouslySetInnerHTML={{ __html: item.icon }} />
              {item.name}
            </Link>
          );
        })}
      </div>

      <button
        onClick={auth}
        className="flex items-center h-[50px] gap-4 text-[14px] px-4 border-t-[1px] border-t-gray-700 text-gray-300 hover:bg-gray-800 hover:text-gray-100 hover:border-r-4 hover:border-r-gray-700 w-full absolute bottom-0"
      >
        <svg
          viewBox="0 0 900 1000"
          fill="currentColor"
          height="1em"
          width="1em"
        >
          <path d="M502 850V750h98v100c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 876.667 0 850V150c0-28 10-51.667 30-71s43.333-29 70-29h400c28 0 51.667 9.667 71 29s29 43 29 71v150h-98V150H100v700h402m398-326L702 720V600H252V450h450V330l198 194" />
        </svg>

        {!userData ? <span>Login</span> : <span>Logout</span>}
      </button>
    </div>
  );
}
