import React from "react";
import globals from "../globals/globals";

export default function Comment(props) {
  const { item } = props;
  return (
    <div className="text-sm border-b-[1px] border-b-gray-900 pb-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 mb-4">
          <img
            src={item.user.profilePicture || globals.NOPROFILE}
            alt={`${item.user.firstName} ${item.user.lastName} Festinekt`}
            className="w-[40px] h-[40px] rounded-full"
          />
          <div className="">
            <p>
              {item.user.firstName} {item.user.lastName}
            </p>

            <p className="text-[12px] text-gray-400">{item.user.username}</p>
          </div>
        </div>

        <svg
          viewBox="0 0 21 21"
          fill="currentColor"
          className="w-[40px] h-[40px]"
        >
          <g fill="currentColor" fillRule="evenodd">
            <path d="M11.5 10.5 A1 1 0 0 1 10.5 11.5 A1 1 0 0 1 9.5 10.5 A1 1 0 0 1 11.5 10.5 z" />
            <path d="M11.5 5.5 A1 1 0 0 1 10.5 6.5 A1 1 0 0 1 9.5 5.5 A1 1 0 0 1 11.5 5.5 z" />
            <path d="M11.5 15.5 A1 1 0 0 1 10.5 16.5 A1 1 0 0 1 9.5 15.5 A1 1 0 0 1 11.5 15.5 z" />
          </g>
        </svg>
      </div>

      <p className="text-gray-400">{item.comment}</p>
    </div>
  );
}
