import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";

export default function Navs() {
  return (
    <div>
      <Header />
      <Sidebar />
    </div>
  );
}
