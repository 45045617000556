import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppContainer from "./components/app-container";
import ErrorPage from "./components/error.page";
import Posts from "./pages/posts";
import ContactUs from "./pages/contact-us";
import Profile from "./pages/admin-profile";
import Login from "./pages/login";
import Signup from "./pages/signup";
import AboutFestinekt from "./pages/about-festinekt";
import SearchResults from "./pages/search-results";
import PostDetails from "./pages/post-details";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsAndConditions from "./pages/terms-and-conditions";
import CreateBlog from "./pages/admin-create-post";
import AdminContainer from "./components/admin-container";
import Blog from "./pages/admin-blog";
import AdminUsers from "./pages/admin-users";
import AdminProfile from "./pages/admin-profile";
import BlogCategorDetails from "./pages/blog-category-details";
import Unauthorized from "./components/unauthorized";
import Search from "./pages/search";
import AdminBlogPost from "./pages/admin-blog-post";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "about-festinekt",
      element: <AboutFestinekt />,
    },
    {
      path: "unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/",
      element: <AppContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "blog/category/:slug",
          element: <BlogCategorDetails />,
        },
        {
          path: "posts",
          element: <Posts />,
        },
        {
          path: "post/:slug",
          element: <PostDetails />,
        },
        {
          path: "search",
          element: <Search />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "search-results",
          element: <SearchResults />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "terms-and-conditions",
          element: <TermsAndConditions />,
        },

        {
          path: "admin/blog/create-blog",
          element: <CreateBlog />,
        },
        {
          path: "admin/blog",
          element: <Blog />,
        },
        {
          path: "admin/blog/post/:slug",
          element: <AdminBlogPost />,
        },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
}

export default App;
