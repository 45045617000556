import React from "react";
import Ad from "./ad";

export default function Container(props) {
  const { children, className } = props;
  return (
    <div
      className={`flex relative justify-between gap-10 p-4 md:p-10 ${className}`}
    >
      {children}
    </div>
  );
}
