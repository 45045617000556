import moment from "moment";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import globals from "../globals/globals";
import { AppContext } from "../context/app-context";

export default function BlogPost(props) {
  const { userData } = useContext(AppContext);

  const { item, className, admin } = props;
  return (
    <Link
      to={
        (userData && userData.roleID === "blogger") ||
        (userData && userData.roleID === "blogdmin") ||
        (userData && userData.roleID === "admin") ||
        (userData && userData.roleID === "superAdmin")
          ? `/admin/blog/post/${item.slug}`
          : `/post/${item.slug}`
      }
      state={item}
      className={`rounded-lg border-[1px] border-gray-800 bg-gray-900 h-[500px] hover:text-white hover:scale-[1.02] transition-transform duration-300 transform hover:z-20 hover:border-gray-600 overflow-hidden ${className}`}
    >
      <img
        src={item.image}
        alt={item.title}
        className="h-[60%] w-full object-cover rounded-t-lg  hover:scale-[1.05] transition-transform duration-300 transform hover:z-20 "
      />

      <div className="p-4 flex flex-col justify-between h-[40%]">
        <div className="flex items-center justify-between gap-2">
          <button className="rounded-lg bg-gray-800 text-myBlue flex items-center justify-center text-[12px] w-1/2 p-2">
            {item.category?.name || "Entertainment"}
          </button>

          <p className="text-[12px] text-myBlue">
            {item.readTime} Minutes Read
          </p>
        </div>

        <h1 className="text-[16px] md:text-[20px]">
          {item.title.length < 80
            ? item.title
            : item.title.slice(0, 79) + "..."}
        </h1>

        <div className="flex items-center justify-between text-[12px] text-gray-400">
          <div className="flex gap-2 items-center">
            <img
              src={
                item.user.profilePicture
                  ? item.user.profilePicture
                  : globals.NOPROFILE
              }
              alt={`${item.user.firstName} ${item.user.lastName} Festinekt`}
              className="w-[20px] rounded-full"
            />
            <p>
              {item.user.firstName} {item.user.lastName}
            </p>
          </div>

          <p>{moment(item.createdAt).format("ll")}</p>
        </div>
      </div>
    </Link>
  );
}
