import React from "react";

export default function PrimaryInput(props) {
  const {
    required,
    value,
    onChange,
    multiple,
    placeholder,
    type,
    className = "bg-myWhite",
    px = "px-8",
  } = props;
  return (
    <input
      required={required}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      multiple={multiple}
      className={`h-[50px] rounded-lg w-full ${className} ${px}`}
    />
  );
}
