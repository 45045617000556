import React, { useContext } from "react";
import { AppContext } from "../context/app-context";
import Title from "./title";

export default function NotificationDropdown() {
  const { notificationDropdown, setNotificationDropdown } =
    useContext(AppContext);

  return (
    <div
      onClick={() => setNotificationDropdown(!notificationDropdown)}
      className="fixed left-0 right-0 z-50 w-full bg-[rgba(0,0,0,0.6)] md:inset-0 "
    >
      <div className="h-[50%] w-[300px] bg-gray-800 rounded-lg p-6 absolute right-10 top-[80px] gap-4">
        <Title>Notifications</Title>
      </div>
    </div>
  );
}
