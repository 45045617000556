import React from "react";

export default function RightComponent(props) {
  return (
    <div
      className={`w-[30%] hidden lg:block bg-gray-900 sticky top-[100px] rounded-lg h-full p-10 ${props.className}`}
    >
      {props.children}
    </div>
  );
}
