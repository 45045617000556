import React, { useContext } from "react";
import { AppContext } from "../context/app-context";

export default function PostOptions() {
  const {
    postDropdown,
    setPostDropdown,
    storyModal,
    setStoryModal,
    postModal,
    setPostModal,
  } = useContext(AppContext);
  return (
    <div
      onClick={() => {
        setPostDropdown(!postDropdown);
      }}
      className="fixed left-0 right-0 z-50 w-full bg-[rgba(0,0,0,0.6)] md:inset-0 cursor-pointer"
    >
      <div className="w-[300px] bg-gray-800 rounded-lg absolute flex flex-col right-10 top-[80px]">
        <p
          onClick={() => setStoryModal(!storyModal)}
          className="p-4 w-full hover:bg-gray-600 border-b-[1px] border-b-gray-700"
        >
          Add story
        </p>

        <p
          onClick={() => setPostModal(!postModal)}
          className="p-4 hover:bg-gray-600"
        >
          Create a post
        </p>
      </div>
    </div>
  );
}
