import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/app-context";
import NotificationDropdown from "./notification-dropdown";
import PostOptions from "./post-options";
import AddStory from "./add-story";
import CreatePost from "./create-post";
import MobileMenu from "./mobile-menu";

export default function Header() {
  const {
    notificationDropdown,
    setNotificationDropdown,
    postDropdown,
    setPostDropdown,
    storyModal,
    postModal,
    menuOpen,
    setMenuOpen,
    theme,
    setTheme,
    userData,
  } = useContext(AppContext);

  return (
    <div className="h-[80px] w-full bg-gray-900 shadow-lg fixed right-0 top-0 z-40 flex justify-between items-center px-4 lg:px-10  bg-opacity-80 backdrop-blur-lg">
      <div className="flex items-center">
        <Link to={"/"} className="flex items-center justify-center">
          <img
            src="/festinekt-for-dark.png"
            alt="Festinekt logo"
            className="object-contain w-[170px]"
          />
        </Link>

        <div className="ml-28 ">
          <div className="relative hidden lg:block">
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              className="text-gray-400 w-[16px] absolute top-[8px] left-[8px]"
            >
              <path d="M19.023 16.977a35.13 35.13 0 01-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0016 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z" />
            </svg>
            <input
              type="text"
              className="appearance-none rounded-lg h-[30px] bg-gray-800 px-8 text-white text-[12px] border-0 focus:border-[1px] ring-0 focus:outline-none focus:border-gray-700"
              placeholder="Search something..."
            />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Link>
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-[30px] text-gray-400 cursor-pointer hover:text-white lg:hidden"
          >
            <path d="M10 18a7.952 7.952 0 004.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z" />
            <path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 00-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z" />
          </svg>
        </Link>

        {theme === "light" ? (
          <svg
            onClick={() => {
              setTheme("dark");
            }}
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            className="w-[25px] text-gray-400 cursor-pointer hover:text-white"
          >
            <path d="M12 3a6 6 0 009 9 9 9 0 11-9-9z" />
          </svg>
        ) : (
          <svg
            onClick={() => {
              setTheme("light");
            }}
            viewBox="0 0 700 1000"
            fill="currentColor"
            className="w-[25px] text-gray-400 cursor-pointer hover:text-white"
          >
            <path d="M350 340c45.333 0 83.333 15.667 114 47s46 69 46 113c0 45.333-15.333 83.333-46 114s-68.667 46-114 46c-44 0-81.667-15.333-113-46s-47-68.667-47-114c0-44 15.667-81.667 47-113s69-47 113-47m0 264c29.333 0 53.667-10 73-30s29-44.667 29-74c0-28-9.667-52-29-72s-43.667-30-73-30c-28 0-52 10-72 30s-30 44-30 72c0 29.333 10 54 30 74s44 30 72 30M50 460c13.333 0 25 4 35 12s15 17.333 15 28c0 26.667-16.667 40-50 40S0 526.667 0 500c0-10.667 5-20 15-28s21.667-12 35-12m546-204c18.667 18.667 16 40-8 64-9.333 9.333-20.333 14.667-33 16-12.667 1.333-22.333-2-29-10-8-8-11.333-18.333-10-31 1.333-12.667 6.667-23.667 16-33 24-22.667 45.333-24.667 64-6m54 204c13.333 0 25 4 35 12s15 17.333 15 28c0 26.667-16.667 40-50 40-32 0-48-13.333-48-40 0-10.667 4.667-20 14-28s20.667-12 34-12M350 750c10.667 0 20 5 28 15s12 21.667 12 35c0 13.333-4 25-12 35s-17.333 15-28 15c-10.667 0-20-5-28-15s-12-21.667-12-35c0-13.333 4-25 12-35s17.333-15 28-15m-238-62c24-24 45.333-26.667 64-8s16 40-8 64c-9.333 9.333-20.333 14.667-33 16-12.667 1.333-22.333-1.333-29-8-20-18.667-18-40 6-64m-10-430c18.667-18.667 40-16 64 8 9.333 9.333 14.667 20.333 16 33 1.333 12.667-1.333 22.333-8 29-20 18.667-41.333 16.667-64-6-24-24-26.667-45.333-8-64m432 484c-22.667-24-24.667-45.333-6-64s40-16 64 8c9.333 9.333 14.667 20.333 16 33 1.333 12.667-2 22.333-10 29-20 18.667-41.333 16.667-64-6M350 250c-10.667 0-20-5-28-15s-12-21.667-12-35c0-13.333 4-25 12-35s17.333-15 28-15c10.667 0 20 5 28 15s12 21.667 12 35c0 13.333-4 25-12 35s-17.333 15-28 15" />
          </svg>
        )}

        {((userData && userData.roleID === "blogger") ||
          (userData && userData.roleID === "blogAdmin") ||
          (userData && userData.roleID === "admin") ||
          (userData && userData.roleID === "superAdmin")) && (
          <>
            <Link to={"/admin/blog/create-blog"}>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                className="w-[25px] text-gray-400 cursor-pointer hover:text-white"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8a8 8 0 100 16 8 8 0 000-16z"
                  clipRule="evenodd"
                />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M13 7a1 1 0 10-2 0v4H7a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V7z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </>
        )}

        <Link to={"/profile"} className="hidden lg:block">
          <img
            className="w-[25px] h-[25px] rounded-full bg-gray-500 border-2 border-gray-500 hover:border-white cursor-pointer object-cover"
            src="https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg"
            alt=""
          />
        </Link>

        {menuOpen ? (
          <svg
            onClick={() => setMenuOpen(!menuOpen)}
            fill="none"
            viewBox="0 0 24 24"
            className="w-[30px] text-gray-400 cursor-pointer hover:text-white lg:hidden"
          >
            <path
              fill="currentColor"
              d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
            />
          </svg>
        ) : (
          <svg
            onClick={() => setMenuOpen(!menuOpen)}
            fill="none"
            viewBox="0 0 24 24"
            className="w-[30px] text-gray-400 cursor-pointer hover:text-white lg:hidden"
          >
            <path
              fill="currentColor"
              d="M22 18.005c0 .55-.446.995-.995.995h-8.01a.995.995 0 010-1.99h8.01c.55 0 .995.445.995.995zM22 12c0 .55-.446.995-.995.995H2.995a.995.995 0 110-1.99h18.01c.55 0 .995.446.995.995zM21.005 6.99a.995.995 0 000-1.99H8.995a.995.995 0 100 1.99h12.01z"
            />
          </svg>
        )}
      </div>

      {menuOpen && <MobileMenu />}
      {postDropdown && <PostOptions />}
      {notificationDropdown && <NotificationDropdown />}
      {storyModal && <AddStory />}
      {postModal && <CreatePost />}
    </div>
  );
}
