import React, { createContext, useState } from "react";
import secureLocalStorage from "react-secure-storage";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(
    secureLocalStorage.getItem("userData") || null
  );
  const [userActionDropdown, setUserActionDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [postDropdown, setPostDropdown] = useState(false);
  const [storyModal, setStoryModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [theme, setTheme] = useState(
    secureLocalStorage.getItem("theme") || "dark"
  );
  const [nextRoute, setNextRoute] = useState(
    secureLocalStorage.getItem("nextRoute") || null
  );

  return (
    <AppContext.Provider
      value={{
        userData,
        setUserData,

        notificationDropdown,
        setNotificationDropdown,

        postDropdown,
        setPostDropdown,

        storyModal,
        setStoryModal,

        postModal,
        setPostModal,

        menuOpen,
        setMenuOpen,

        googleLogin,
        setGoogleLogin,

        theme,
        setTheme,

        nextRoute,
        setNextRoute,

        userActionDropdown,
        setUserActionDropdown,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
