import React, { useContext, useEffect, useState } from "react";
import Title from "../components/title";
import { Link, useNavigate } from "react-router-dom";
import Label from "../components/label";
import TextInput from "../components/text-input";
import PrimaryButton from "../components/primary-button";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import globals from "../globals/globals";
import secureLocalStorage from "react-secure-storage";
import { AppContext } from "../context/app-context";
import { Helmet } from "react-helmet";
import { useGoogleLogin } from "@react-oauth/google";
import LoadingData from "../components/loading-data";

export default function Login() {
  const { userData, setUserData, setMenuOpen, nextRoute, setNextRoute } =
    useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setprocessing] = useState(false);
  const [processingGoogle, setProccessingGoogle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (
        userData.roleID === "superAdmin" ||
        userData.roleID === "admin" ||
        userData.roleID === "blogAdmin" ||
        userData.roleID === "blogger"
      ) {
        navigate("/admin");
      } else {
        navigate("/");
      }
    }
  }, [navigate, userData]);

  async function login(e) {
    try {
      e.preventDefault();
      if (!email) {
        toast.error("Email is required");
      } else if (!password) {
        toast.error("Password is required");
      } else {
        const data = {
          email,
          password,
        };
        setprocessing(true);

        const url = `${globals.ENDPOINT}/api/user/auth/login`;
        const headers = {
          "auth-token": globals.TOKEN,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        });

        const responseData = await response.json();
        setprocessing(false);

        if (responseData.status === "Success") {
          setUserData(responseData.data);
          secureLocalStorage.setItem("userData", responseData.data);
          setMenuOpen(false);

          if (nextRoute) {
            navigate(nextRoute);

            setNextRoute(null);
            secureLocalStorage.setItem("nextRoute", null);
          }
          //  else if (
          //   responseData.data.roleID === "superAdmin" ||
          //   responseData.data.roleID === "admin" ||
          //   responseData.data.roleID === "blogAdmin" ||
          //   responseData.data.roleID === "blogger"
          // ) {
          //   navigate("/admin");
          // }
          else {
            navigate("/");
          }
        } else {
          toast.error(responseData.message);
        }
      }
    } catch (error) {
      setprocessing(false);
      toast.error("An error occurred while logging in");
    }
  }

  async function googleLogin() {
    try {
      setProccessingGoogle(true);
      submit();
    } catch (error) {
      toast.error("An error occured while logging in with google");
    }
  }

  const submit = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setProccessingGoogle(true);
      const accessToken = codeResponse.access_token;
      //get user data
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          //handle login
          const googleData = res.data;
          const headers = {
            "auth-token": globals.TOKEN,
          };
          const apiRes = await axios.post(
            `${globals.ENDPOINT}/api/user/auth/google-login`,
            googleData,
            { headers }
          );

          if (apiRes.data.status === "Success") {
            setUserData(apiRes.data.data);
            secureLocalStorage.setItem("userData", apiRes.data.data);
            setMenuOpen(false);
            setProccessingGoogle(false);

            if (nextRoute) {
              navigate(nextRoute);

              setNextRoute(null);
              secureLocalStorage.setItem("nextRoute", null);
            } else if (
              apiRes.data.roleID === "superAdmin" ||
              apiRes.data.roleID === "admin" ||
              apiRes.data.roleID === "blogAdmin" ||
              apiRes.data.roleID === "blogger"
            ) {
              navigate("/admin");
            } else {
              navigate("/");
            }
          } else {
            toast.error(apiRes.data.message);
            setProccessingGoogle(false);
          }
        })
        .catch((err) => {
          setProccessingGoogle(false);
          toast.error("An error occured");
        });
    },
    onError: (error) => {
      setProccessingGoogle(false);
      toast.error("An error occured");
    },
  });

  return (
    <div className="flex">
      <Helmet>
        <title>Login - Festinekt</title>
        <meta
          name="description"
          content="Elevate your online experience with Festinekt Blog - your ultimate source for Nairobi's events, breaking news, and entertainment. Explore curated content on fashion, food and nutrition, health, and wellness, alongside comprehensive coverage of Nairobi's vibrant cultural scene. Stay at the forefront of SEO-optimized insights, events, and entertainment with Festinekt Blog."
        />
      </Helmet>
      <Toaster />
      {processingGoogle && <LoadingData />}
      <div className="w-full md:w-[30%] bg-gray-900 min-h-screen p-10">
        <div className="flex items-center justify-center">
          <img
            src="/festinekt-for-dark.png"
            alt="Festinekt logo"
            className="object-contain w-[80%]"
          />
        </div>

        <Title className="text-[30px] mt-10 text-gray-300">
          Log in to you account
        </Title>

        <form onSubmit={login} className="mt-10">
          <Label>Email Address*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="e.g johndoe@gmail.com / 254724753175"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />

          <Label myStyles="mt-4">Password*</Label>
          <TextInput
            className="h-[50px] w-full mt-2"
            placeholder="********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />

          <PrimaryButton processing={processing} className="w-full mt-4">
            Login
          </PrimaryButton>
        </form>

        <div className="flex items-center justify-center mt-4 gap-4">
          <hr
            style={{ width: "100%", height: "1px", backgroundColor: "black" }}
          />
          <p>OR</p>
          <hr
            style={{ width: "100%", height: "1px", backgroundColor: "black" }}
          />
        </div>

        <button
          onClick={googleLogin}
          className="h-[50px] border-2 border-myBlue rounded-lg w-full flex items-center justify-center gap-4 px-10 mt-4"
        >
          <img
            src="/google-logo.png"
            alt="google-logo-festinekt"
            className="w-[30px] h-[30px]"
          />
          <span className="text-myBlue">Login with Google</span>
        </button>

        <p className="mt-4 text-center">
          Don't have an account ?{" "}
          <Link className="text-myBlue font-bold" to={"/signup"}>
            Sigup
          </Link>
        </p>
      </div>

      <div className="hidden w-[70%] bg-gray-800 p-10 md:flex items-center">
        <div className="w-[40%]">
          <Title className="text-[30px] mb-10 font-bold">
            Your Ticket To Unforgettable Moments
          </Title>
          <h1>
            We know you're looking for fun and connecting with mutuals that's
            why we present to you{" "}
            <span className="text-orange-500">Festinekt.</span> We bring all the
            fun and connections to one platform, making it easy for you to
            access it from anywhere around the world.
          </h1>

          <Link
            to="/about-festinekt"
            className="text-myBlue font-bold underline flex items-center gap-2"
          >
            Learn more
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
            >
              <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
              />
            </svg>
          </Link>
        </div>

        <img
          src="/login-image.png"
          alt="Festinekt login"
          className="w-[60%] h-full object-cover"
        />
      </div>
    </div>
  );
}
