import React, { useContext, useEffect, useState } from "react";
import AdminInner from "../components/admin-inner";
import globals from "../globals/globals";
import { Helmet } from "react-helmet";
import BlogPost from "../components/blog-post";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LoadingData from "../components/loading-data";
import Container from "../components/container";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/app-context";

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const { userData } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate("/login");
    } else if (
      userData.roleID !== "blogger" &&
      userData.roleID !== "blogAdmin" &&
      userData.roleID !== "admin" &&
      userData.roleID !== "superAdmin"
    ) {
      navigate("/unauthorized");
    } else {
      getPosts();
    }
  }, []);

  const headers = {
    "auth-token": globals.TOKEN,
  };

  async function getPosts() {
    const url = `${globals.ENDPOINT}/api/blog/post`;

    await axios
      .get(url, { headers })
      .then((response) => {
        setLoadingData(false);

        if (response.data.status === "Success") {
          setPosts(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        toast.error("An error occured while getting posts");
      });
  }

  /**
   *
   * Categories list
   */

  return (
    <Container>
      <Helmet>
        <title>
          Festinekt Blog | Nairobi Events, Breaking News, Entertainment
        </title>
        <meta
          name="description"
          content="Discover the pulse of Nairobi's vibrant events scene and stay informed with Festinekt Blog. Explore breaking news, entertainment highlights, and all things Kenyan culture. Dive into our insightful content covering Nairobi events and entertainment across Kenya. Stay connected with the beat of the city."
        />
      </Helmet>
      <Toaster />

      {loadingData && <LoadingData />}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-gray-400">
        {posts.map((item) => (
          <BlogPost key={item._id} item={item} admin={true} />
        ))}
      </div>
    </Container>
  );
}
