import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Home from "../pages/home";
import Navs from "./navs";

export default function AppContainer() {
  const location = useLocation();

  return (
    <div>
      <Navs />

      <div className="min-h-screen w-full lg:w-[80%] float-right mt-[80px]">
        {location.pathname === "/" && <Home />}
        <Outlet />
      </div>
    </div>
  );
}
