import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  document.title = `Error - Festinekt`;

  return (
    <div className="flex items-center justify-center h-screen" id="error-page">
      <div>
        <h1 className="font-bold text-center">Oops!</h1>
        <p className="text-center">Sorry, an unexpected error has occurred.</p>
        <p className="text-center">
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
}
