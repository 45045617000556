import React from "react";

export default function PrimaryButton(props) {
  const { processing, disabled, onClick } = props;
  return (
    <button
      disabled={processing || disabled}
      onClick={onClick}
      className={`h-[50px] rounded-lg bg-myBlue ${
        (processing || disabled) && `opacity-40`
      } ${props.className}`}
    >
      {processing ? "Processing..." : props.children}
    </button>
  );
}
