import React from "react";

export default function TextInput(props) {
  const { type, placeholder, onChange, value, required, className } = props;
  return (
    <input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      required={required}
      className={`appearance-none rounded-lg h-[30px] bg-gray-800 px-4 text-white text-[12px] border-0 focus:border-[1px] ring-0 focus:outline-none focus:border-gray-700 ${className}`}
    />
  );
}
