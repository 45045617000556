import React, { useContext, useEffect, useState } from "react";

import PrimaryButton from "../components/primary-button";
import axios from "axios";
import { AppContext } from "../context/app-context";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Label from "../components/label";
import PrimaryInput from "../components/primary-input";
import { FileUploader } from "react-drag-drop-files";
import globals from "../globals/globals";
import Container from "../components/container";
import LeftComponent from "../components/left-component";
import LoadingData from "../components/loading-data";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default function CreateBlog() {
  const { userData } = useContext(AppContext);
  const [title, setTitle] = useState("");
  const [preview, setPreview] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const [uploadingImage, setUploadingImage] = useState(false);
  const [processing, setProcessing] = useState(false);

  const navigate = useNavigate();

  const fileTypes = ["jpg", "png", "jpeg", "webp"];

  useEffect(() => {
    if (!userData) {
      navigate("/login");
    } else if (
      userData.roleID !== "blogger" &&
      userData.roleID !== "blogAdmin" &&
      userData.roleID !== "superAdmin" &&
      userData.roleID !== "dmin"
    ) {
      navigate("/unauthorized");
    } else {
      getCategories();
    }
  }, []);

  const headers = {
    "auth-token": globals.TOKEN,
  };

  async function getCategories() {
    try {
      const response = await axios.get(
        `${globals.ENDPOINT}/api/blog/category`,
        { headers }
      );
      setLoadingData(false);
      if (response.data.status === "Success") {
        setCategories(response.data.data);
        setCategory(response.data.data[0]._id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      toast.error("An error occured while getting categories");
    }
  }

  const url = globals.ENDPOINT + "/api/file-upload";

  const config = {
    extraPlugins: [uploadPlugin],
    mediaEmbed: {
      previewsInData: true,
    },
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            fetch(url, {
              method: "POST",
              body,
              headers: {
                "auth-token": globals.TOKEN,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: res.url });
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleChange = async (file) => {
    try {
      setUploadingImage(true);

      const data = new FormData();
      data.append("file", file);

      const response = await axios.post(url, data, { headers });
      console.log(response);
      setUploadingImage(false);
      if (response.data.uploaded) {
        setImage(response.data.url);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setUploadingImage(false);
      toast.error("An error occured");
    }
  };

  async function createPost(e) {
    try {
      e.preventDefault();
      setProcessing(true);
      const data = {
        userID: userData._id,
        title,
        content,
        image,
        category,
        preview,
      };
      const response = await axios.post(
        `${globals.ENDPOINT}/api/blog/post`,
        data,
        {
          headers: {
            "auth-token": userData.token,
          },
        }
      );
      setProcessing(false);
      if (response.data.status === "Success") {
        toast.success(response.data.message);
        setImage("");
        setTitle("");
        setContent("");
        setCategory("");
        setPreview("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setProcessing(false);
      toast.error("An error occured");
    }
  }

  return (
    <Container>
      <Toaster />
      {loadingData && <LoadingData />}

      <LeftComponent className="">
        <h1 className="font-black text-[40px] mb-4">CREATE A BLOG POST</h1>
        <div className="z-0 text-black">
          <Label myStyles="mb-4 text-gray-50">Display image*</Label>

          <FileUploader
            multiple={false}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          >
            <div className="flex items-center justify-center w-full">
              <label className="px-2 flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-sm cursor-pointer bg-gray-50 hover:bg-purple-50 overflow-auto">
                {image ? (
                  <img src={image} alt="" className="object-cover" />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      className="w-10 h-10 text-gray-400"
                    >
                      <path d="M.002 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2h-12a2 2 0 01-2-2V3zm1 9v1a1 1 0 001 1h12a1 1 0 001-1V9.5l-3.777-1.947a.5.5 0 00-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 00-.63.062L1.002 12zm5-6.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                    </svg>

                    {uploadingImage ? (
                      <p className="text-gray-400 text-sm mt-4">
                        Processing image...
                      </p>
                    ) : (
                      <p className="text-gray-400 text-sm mt-4">
                        Click to add an image or drag and drop
                      </p>
                    )}
                  </div>
                )}
              </label>
            </div>
          </FileUploader>

          <Label myStyles="mb-4 mt-4 text-gray-50">Post Title*</Label>
          <PrimaryInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="e.g An Introvert's Guide to Being Successful at Work."
            className="mb-4 text-black rounded-sm"
            px="px-2"
            type="text"
            required={true}
            maxLength={100}
          />

          <Label myStyles="mb-4 mt-4 text-gray-50">Post preview*</Label>
          <textarea
            value={preview}
            onChange={(e) => setPreview(e.target.value)}
            placeholder="e.g An Introvert's Guide to Being Successful at Work."
            className="mb-4 text-black rounded-sm w-full p-2 min-h-[50px]"
            maxLength={240}
          ></textarea>

          <Label myStyles="mb-4 text-gray-50">Content*</Label>

          {((userData && userData.roleID === "blogger") ||
            (userData && userData.roleID === "blogAdmin") ||
            (userData && userData.roleID === "admin") ||
            (userData && userData.roleID === "superAdmin")) && (
            <CKEditor
              config={config}
              editor={Editor}
              data={content}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          )}

          <Label myStyles="my-4 text-gray-50">Category*</Label>

          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={`h-[50px] rounded-sm px-2 w-full `}
          >
            {categories.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>

          {/* <div className="text-white ck-content">{parse(content)}</div> */}

          <PrimaryButton
            processing={processing}
            disabled={!image || !title || !content || !preview}
            onClick={createPost}
            className="w-full mt-10 text-white"
          >
            Create Post
          </PrimaryButton>
        </div>
      </LeftComponent>
    </Container>
  );
}
