import React, { useEffect, useState } from "react";
import Container from "../components/container";
import Post from "../components/post";
import { Helmet } from "react-helmet";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import globals from "../globals/globals";
import LoadingData from "../components/loading-data";
import Title from "../components/title";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getPosts();
  }, []);

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;

    const body = document.body;
    const html = document.documentElement;

    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight - 100 && !loadingMore) {
      if (!endReached) {
        const newPage = page + 1;
        setPage(newPage);
        loadMore(newPage);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const headers = {
    "auth-token": process.env.REACT_APP_ANONYMOUS_AUTH_TOKEN,
  };

  async function getPosts() {
    const url = `${globals.ENDPOINT}/api/post/get-all-posts?page=${page}&searchTerm=${searchTerm}`;

    try {
      const response = await axios.get(`${url}`, { headers });

      setLoadingMore(false);
      setLoadingData(false);
      if (response.data.status === "Success") {
        if (posts.length === response.data.data.count) {
          setEndReached(true);
        }
        setPosts(response.data.data.posts);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      setLoadingMore(false);
      toast.error(error.message);
    }
  }

  async function loadMore(newPage) {
    setLoadingMore(true);

    const url = `${globals.ENDPOINT}/api/post/get-all-posts?page=${newPage}&searchTerm=${searchTerm}`;

    try {
      const response = await axios.get(`${url}`, { headers });
      setLoadingMore(false);
      if (response.data.status === "Success") {
        if (
          response.data.data.posts.length === 0 ||
          posts.length === response.data.data.count
        ) {
          setEndReached(true);
        } else {
          setPosts((prevData) => [...prevData, ...response.data.data.posts]);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingMore(false);
      toast.error(error.message);
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Posts - Festinekt</title>
        <meta
          name="description"
          content="Just like any other social media app, festinekt allows users to create posts that can be visible to all other users with the application. This enable interactions between users"
        />
      </Helmet>

      <Toaster />
      {loadingData && <LoadingData />}

      <div className="flex justify-between gap-10">
        <div className="w-[60%]">
          {posts.map((item, i) => (
            <Post item={item} key={i} myStyles="mb-10" />
          ))}

          {loadingMore ? (
            <p className="text-center">Loading more posts...</p>
          ) : (
            <p className="text-center text-gray-500">No more posts</p>
          )}
        </div>

        <div className="w-[40%] bg-gray-900 rounded-lg p-8 sticky top-[100px] h-full">
          <Title>Trending topics</Title>
          <p className="text-gray-400 text-[12px]">No topics available</p>
          {/* <ol className="text-sm text-gray-400 ml-4">
            <li>1. House party</li>
            <li>2. Mihadarati</li>
            <li>3. Happy weekend</li>
          </ol> */}
        </div>
      </div>
    </Container>
  );
}
