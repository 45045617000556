import React, { useContext } from "react";
import { AppContext } from "../context/app-context";

export default function AddStory() {
  const { storyModal, setStoryModal } = useContext(AppContext);
  return (
    <div
      onClick={() => setStoryModal(!storyModal)}
      className="fixed left-0 right-0 z-50 w-full bg-[rgba(0,0,0,0.6)] md:inset-0 flex items-center justify-center"
    >
      <div className="h-[50%] w-[300px] bg-gray-800 rounded-lg p-6 top-[80px] gap-4"></div>
    </div>
  );
}
