import React, { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import axios from "axios";
import globals from "../globals/globals";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AppContext } from "../context/app-context";
import toast, { Toaster } from "react-hot-toast";
import LoadingData from "../components/loading-data";
import { useLocation, useParams } from "react-router-dom";
import LeftComponent from "../components/left-component";
import BlogPost from "../components/blog-post";
import Title from "../components/title";
import RightComponent from "../components/right-component";

export default function Home() {
  const [posts, setPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState(null);

  const { state } = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    setLoadingData(true);
    if (!state) {
      getCategory();
    } else {
      getPosts(state._id);
    }
  }, [slug]);

  const headers = {
    "auth-token": process.env.REACT_APP_ANONYMOUS_AUTH_TOKEN,
  };

  async function getCategory() {
    try {
      const url = `${globals.ENDPOINT}/api/blog/category/${slug}`;
      const response = await axios.get(url, { headers });
      if (response.data.status === "Success") {
        setCategory(response.data.data);

        getPosts(response.data.data._id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occured while getting category");
    }
  }

  async function getPosts(categoryID) {
    const url = `${globals.ENDPOINT}/api/blog/post?page=${page}&searchTerm=${searchTerm}&category=${categoryID}`;

    try {
      const response = await axios.get(`${url}`, { headers });
      setLoadingData(false);
      if (response.data.status === "Success") {
        setPosts(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      toast.error(error.message);
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{state?.name || category?.name || "Blog"} - Festinekt</title>
        <meta
          name="description"
          content={state?.description || category?.description}
        />
      </Helmet>
      <Toaster />
      {loadingData && <LoadingData />}

      <LeftComponent>
        <Title className="text-[40px] font-black mb-10">
          {state?.name || category?.name}
        </Title>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-400">
          {posts.map((item) => (
            <BlogPost key={item._id} item={item} />
          ))}
        </div>
      </LeftComponent>
    </Container>
  );
}
