import React from "react";
import Container from "../components/container";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <Container>
      <Helmet>
        <title>Privacy Policy || Festinekt</title>
        <meta
          name="description"
          content="This Privacy Policy explains how Festinekt
          collects, uses, discloses, and protects information about users
          of our web application and related services ."
        />
      </Helmet>
      <div className="container mx-auto px-4 py-8 text-gray-300">
        <h1 className="text-3xl font-bold mb-4 text-orange-500">
          Privacy Policy for Festinekt
        </h1>

        <p className="mb-4">
          This Privacy Policy explains how Festinekt ("we," "us," or "our")
          collects, uses, discloses, and protects information about users
          ("you") of our web application and related services (collectively, the
          "Services").
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          1. Information We Collect
        </h2>

        <p className="mb-4">
          1.1 <strong>Information You Provide:</strong> We may collect personal
          information you provide when you use our Services, including but not
          limited to your name, email address, location, interests, and any
          other information you choose to provide.
        </p>

        <p className="mb-4">
          1.2 <strong>Automatically Collected Information:</strong> We may
          automatically collect certain information when you use our Services,
          such as your device type, IP address, device identifiers, browser
          type, operating system, and usage data.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          2. How We Use Your Information
        </h2>

        <p className="mb-4">
          2.1 <strong>To Provide and Improve Our Services:</strong> We use the
          information we collect to provide, maintain, and improve our Services,
          including connecting users, suggesting events, and enhancing user
          experience.
        </p>

        <p className="mb-4">
          2.2 <strong>Communication:</strong> We may use your contact
          information to communicate with you about our Services, including
          updates, promotions, and important notices.
        </p>

        <p className="mb-4">
          2.3 <strong>Analytics:</strong> We may use analytics tools to analyze
          how users interact with our Services and to improve our offerings.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          3. Sharing of Information
        </h2>

        <p className="mb-4">
          3.1 <strong>With Other Users:</strong> Certain information you provide
          may be visible to other users of the Services, such as your name,
          profile picture, and interests.
        </p>

        <p className="mb-4">
          3.2 <strong>With Third Parties:</strong> We may share your information
          with third-party service providers who assist us in providing and
          improving our Services, such as hosting providers, analytics
          providers, and marketing partners. We may also share information in
          response to legal requests or to protect our rights and interests.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          4. Your Choices
        </h2>

        <p className="mb-4">
          4.1 <strong>Account Information:</strong> You may update or delete
          your account information at any time by accessing your account
          settings within the app.
        </p>

        <p className="mb-4">
          4.2 <strong>Communication Preferences:</strong> You may opt out of
          receiving promotional communications from us by following the
          instructions in those communications or by contacting us directly.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          5. Data Security
        </h2>

        <p className="mb-4">
          We take reasonable measures to protect the information we collect from
          loss, misuse, and unauthorized access, disclosure, alteration, and
          destruction.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          6. Children’s Privacy
        </h2>

        <p className="mb-4">
          Our Services are not directed to individuals under the age of 13, and
          we do not knowingly collect personal information from children under
          13. If we become aware that a child under 13 has provided us with
          personal information, we will take steps to delete such information.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">
          7. Changes to This Privacy Policy
        </h2>

        <p className="mb-4">
          We may update this Privacy Policy from time to time. If we make
          material changes, we will notify you by email or by posting a notice
          within the app prior to the change becoming effective. Your continued
          use of the Services after the effective date of any changes
          constitutes your acceptance of the updated Privacy Policy.
        </p>

        <h2 className="text-xl font-semibold mb-2 text-white">8. Contact Us</h2>

        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy or our
          practices regarding your information, please contact us at: <br />
          <br /> Email:{" "}
          <a
            href="mailto:contact@festinekt.com"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            contact@festinekt.com
          </a>
          <br />
          Phone number:{" "}
          <a
            href="tel:+254742836404"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            +254742836404
          </a>
          <br />
          WhatsApp:{" "}
          <a
            href="https://wa.me/254742836404"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            +254742836404
          </a>
        </p>
      </div>
    </Container>
  );
}
