import React, { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import Post from "../components/post";
import Event from "../components/event";
import HotEvents from "../components/hot-events";
import axios from "axios";
import globals from "../globals/globals";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { AppContext } from "../context/app-context";
import toast, { Toaster } from "react-hot-toast";
import LoadingData from "../components/loading-data";
import BlogPost from "../components/blog-post";
import LeftComponent from "../components/left-component";
import RightComponent from "../components/right-component";
import Adsense from "../components/adsense";

export default function Home() {
  const { userData } = useContext(AppContext);
  const [posts, setPosts] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    getPosts();
  }, []);

  const headers = {
    "auth-token": globals.TOKEN,
  };

  async function getPosts() {
    const url = `${globals.ENDPOINT}/api/blog/post?status=1`;

    await axios
      .get(url, { headers })
      .then((response) => {
        setLoadingData(false);

        if (response.data.status === "Success") {
          setPosts(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        toast.error("An error occured while getting posts");
      });
  }

  return (
    <Container>
      <Helmet>
        <title>
          Festinekt Blog | Nairobi Events, Breaking News, Entertainment
        </title>
        <meta
          name="description"
          content="Discover the pulse of Nairobi's vibrant events scene and stay informed with Festinekt Blog. Explore breaking news, entertainment highlights, and all things Kenyan culture. Dive into our insightful content covering Nairobi events and entertainment across Kenya. Stay connected with the beat of the city."
        />
        <meta
          property="og:title"
          content="Festinekt Blog | Nairobi Events, Breaking News, Entertainment"
        />
        <meta
          property="og:description"
          content="Discover the pulse of Nairobi's vibrant events scene and stay informed with Festinekt Blog. Explore breaking news, entertainment highlights, and all things Kenyan culture. Dive into our insightful content covering Nairobi events and entertainment across Kenya. Stay connected with the beat of the city."
        />
        <meta
          property="og:image"
          content="https://blog.festinekt.com/festinekt-logo.png"
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Toaster />

      {loadingData && <LoadingData />}

      <LeftComponent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-400">
          {posts.map((item) => (
            <BlogPost key={item._id} item={item} />
          ))}
        </div>
      </LeftComponent>
    </Container>
  );
}
