import React from "react";
import Container from "../components/container";
import { Helmet } from "react-helmet";

export default function SearchResults() {
  return (
    <Container>
      <Helmet>
        <title>Search results - Festinekt</title>
        <meta
          name="description"
          content="Explore a wealth of information with our search results page. Discover answers to your queries, curated content, and in-depth insights. Navigate a diverse range of topics efficiently and find what you're looking for with our user-friendly search experience."
        />
      </Helmet>
    </Container>
  );
}
