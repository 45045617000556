import React, { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import { Helmet } from "react-helmet";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LeftComponent from "../components/left-component";
import RightComponent from "../components/right-component";
import Title from "../components/title";
import globals from "../globals/globals";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { FacebookShareButton } from "react-share";
import axios from "axios";
import LoadingData from "../components/loading-data";
import { AppContext } from "../context/app-context";
import Comment from "../components/comment";
import secureLocalStorage from "react-secure-storage";
import parse from "html-react-parser";

export default function PostDetails() {
  const { userData, setNextRoute } = useContext(AppContext);
  const { state } = useLocation();
  const [post, setPost] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const { slug } = useParams();
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [processing, setProcessing] = useState(false);

  const shareUrl = window.location.href;
  const hashtag = "#festinekt #festinekt_blog";

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!state) {
      getPost();
    } else {
      setLoadingData(false);
      getComments(state._id);
    }
  }, []);

  const headers = {
    "auth-token": globals.TOKEN,
  };

  async function getPost() {
    try {
      const response = await axios.get(
        `${globals.ENDPOINT}/api/blog/post/${slug}`,
        { headers }
      );
      setLoadingData(false);
      if (response.data.status === "Success") {
        setPost(response.data.data);
        getComments(response.data.data._id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoadingData(false);
      toast.error("An error occured while getting the post");
    }
  }

  async function handleShare(platform) {
    if (platform === "twitter") {
      const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href
      )}&text=${encodeURIComponent(state?.title || post?.title)}`;
      window.open(tweetUrl, "_blank");
    } else if (platform === "instagram") {
      alert(
        "To share on Instagram, please open the Instagram app and share the post manually."
      );
    } else if (platform === "facebook") {
      const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}`;
      window.open(fbUrl, "_blank");
    } else if (platform === "whatsapp") {
      const waUrl = `https://wa.me/?text=${encodeURIComponent(
        `${state?.title || post?.title} - ${window.location.href}`
      )}`;
      window.open(waUrl, "_blank");
    } else {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied to clipboard");
    }
  }

  const authHeaders = {
    "auth-token": userData?.token,
  };

  async function addComment() {
    try {
      if (!userData) {
        const nextRoute = `/post/${slug}`;
        setNextRoute(nextRoute);
        secureLocalStorage.setItem("nextRoute", nextRoute);

        navigate("/login");
      } else {
        let postID = null;
        let userID = userData._id;

        if (state) {
          postID = state._id;
        }
        if (post) {
          postID = post._id;
        }

        if (!postID) {
          toast.error("Post ID is required");
        } else if (!comment) {
          toast.error("Comment is required");
        } else if (!userID) {
          toast.error("User ID is required");
        } else {
          setProcessing(true);
          const data = {
            postID,
            userID,
            comment,
          };

          const response = await axios.post(
            `${globals.ENDPOINT}/api/blog/post/comment`,
            data,
            {
              headers: authHeaders,
            }
          );

          setProcessing(false);
          if (response.data.status === "Success") {
            toast.success(response.data.message);
            setComments([response.data.data, ...comments]);
            setComment("");
          } else {
            toast.error(response.data.message);
          }
        }
      }
    } catch (error) {
      setProcessing(false);
      toast.error("An error occured while adding comment");
    }
  }

  async function getComments(postID) {
    try {
      const url = `${globals.ENDPOINT}/api/blog/post/${postID}/comment`;
      const response = await axios.get(url, { headers });

      if (response.data.status === "Success") {
        setComments(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occured while getting comments");
    }
  }

  return (
    <Container>
      <Helmet>
        <title>
          {`${state?.title || post?.title || "Blog Post"}`} - Festinekt
        </title>
        <meta
          name="description"
          content={`${state?.title || post?.title || "Description"}`}
        />
        <meta
          property="og:title"
          content={`Testing post with preview for arts and culture so please check it out`}
        />
        <meta
          property="og:description"
          content={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled `}
        />
        <meta
          property="og:image"
          content={"https://blog.festinekt.com/festinekt-logo.png"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <Toaster />

      {loadingData && <LoadingData />}

      <LeftComponent>
        <div className="flex items-center justify-between gap-2 mb-4">
          <button className="rounded-lg bg-gray-900 text-myBlue flex items-center justify-center text-[12px] py-2 px-8">
            {state?.category?.name || post?.category?.name || "Entertainment"}
          </button>

          <p className="text-[12px] text-myBlue">
            {state?.readTime || post?.readTime} Minutes Read
          </p>
        </div>

        <Title className="text-[30px] sm:text-[30px] md:text-[40px]">
          {state?.title || post?.title}
        </Title>

        <div className="flex items-center gap-2 text-gray-400 mt-6">
          <div className="flex gap-2 items-center cursor-pointer">
            <img
              src={
                state?.user?.profilePicture
                  ? state?.user?.profilePicture
                  : globals.NOPROFILE
              }
              alt={`${state?.user?.firstName} ${state?.user?.lastName} Festinekt`}
              className="w-[20px] rounded-full"
            />
            <p>
              {state?.user?.firstName || post?.user?.firstName}{" "}
              {state?.user?.lastName || post?.user?.lastName}
            </p>
          </div>
          ~<p>{moment(state?.createdAt || post?.createdAt).format("ll")}</p>
        </div>

        <img
          src={state?.image || post?.image}
          alt={state?.title || post?.title}
          className="rounded-lg my-10 w-full h-[200px] sm:h-[400px] md:h-[500px] object-cover"
        />

        <div className="mt-4">
          <h1>Share with friends</h1>
          <div className="flex items-center text-gray-400 gap-2 mt-2">
            <FacebookShareButton url={shareUrl} hashtag={hashtag}>
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                className="cursor-pointer  hover:text-orange-500 w-[40px] h-[40px]"
              >
                <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-92.4 233.5h-63.9c-50.1 0-59.8 23.8-59.8 58.8v77.1h119.6l-15.6 120.7h-104V912H539.2V602.2H434.9V481.4h104.3v-89c0-103.3 63.1-159.6 155.3-159.6 44.2 0 82.1 3.3 93.2 4.8v107.9z" />
              </svg>
            </FacebookShareButton>
            <svg
              onClick={() => handleShare("twitter")}
              viewBox="0 0 448 512"
              fill="currentColor"
              height="1em"
              width="1em"
              className="cursor-pointer  hover:text-orange-500 w-[40px] h-[40px]"
            >
              <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 01-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z" />
            </svg>

            <svg
              onClick={() => handleShare("whatsapp")}
              viewBox="0 0 448 512"
              fill="currentColor"
              height="1em"
              width="1em"
              className="cursor-pointer  hover:text-orange-500 w-[40px] h-[40px]"
            >
              <path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z" />
            </svg>

            <svg
              onClick={() => handleShare("copy")}
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              className="cursor-pointer  hover:text-orange-500 w-[40px] h-[40px]"
            >
              <path d="M4.715 6.542L3.343 7.914a3 3 0 104.243 4.243l1.828-1.829A3 3 0 008.586 5.5L8 6.086a1.002 1.002 0 00-.154.199 2 2 0 01.861 3.337L6.88 11.45a2 2 0 11-2.83-2.83l.793-.792a4.018 4.018 0 01-.128-1.287z" />
              <path d="M6.586 4.672A3 3 0 007.414 9.5l.775-.776a2 2 0 01-.896-3.346L9.12 3.55a2 2 0 112.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 10-4.243-4.243L6.586 4.672z" />
            </svg>
          </div>
        </div>

        <hr className="border-t-[1px] my-10 border-gray-800" />

        <div
          className="text-gray-300 ck-content"
          dangerouslySetInnerHTML={{ __html: state?.content || post?.content }}
        />

        <hr className="border-t-[1px] my-10 border-gray-800" />

        <div>
          <h1 className="text-[30px] font-black mb-4">Comments</h1>

          <div className="relative">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={`appearance-none rounded-lg w-full bg-gray-800 p-6 pr-10 resize-none text-white text-[12px] border-0 focus:border-[1px] ring-0 focus:outline-none focus:border-gray-700`}
              placeholder="Add your comment here..."
            />

            <button onClick={addComment} disabled={processing || !comment}>
              <svg
                viewBox="0 0 512 512"
                fill="currentColor"
                height="1em"
                width="1em"
                className={`absolute right-4 top-9 ${
                  !comment ? "text-gray-400" : "text-myBlue"
                }`}
              >
                <path d="M476.59 227.05l-.16-.07L49.35 49.84A23.56 23.56 0 0027.14 52 24.65 24.65 0 0016 72.59v113.29a24 24 0 0019.52 23.57l232.93 43.07a4 4 0 010 7.86L35.53 303.45A24 24 0 0016 327v113.31A23.57 23.57 0 0026.59 460a23.94 23.94 0 0013.22 4 24.55 24.55 0 009.52-1.93L476.4 285.94l.19-.09a32 32 0 000-58.8z" />
              </svg>
            </button>
          </div>

          <div className="mt-10 flex flex-col gap-4">
            {comments.map((item) => (
              <Comment key={item._id} item={item} />
            ))}
          </div>
        </div>
      </LeftComponent>

      <RightComponent></RightComponent>
    </Container>
  );
}
