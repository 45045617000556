import React, { useRef, useState } from "react";
import Container from "../components/container";
import Label from "../components/label";
import TextInput from "../components/text-input";
import PrimaryButton from "../components/primary-button";
import Title from "../components/title";
import axios from "axios";
import globals from "../globals/globals";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";

import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  const headers = {
    "auth-token": globals.TOKEN,
  };

  const captchaRef = useRef(null);

  async function submit(e) {
    try {
      e.preventDefault();
      const token = captchaRef.current.getValue();

      if (!token) {
        toast.error("Please verify you're not a robot");
      } else if (!fullName) {
        toast.error("Name is required");
      } else if (!email) {
        toast.error("Email is required");
      } else if (!phoneNumber) {
        toast.error("Phone number is required");
      } else if (!message) {
        toast.error("Message is required");
      } else {
        setProcessing(true);
        const data = {
          name: fullName,
          email,
          phoneNumber,
          message,
        };
        const response = await axios.post(
          `${globals.ENDPOINT}/api/contact-us`,
          data,
          { headers }
        );
        setProcessing(false);
        if (response.data.status === "Success") {
          toast.success(response.data.message);
          setFullName("");
          setEmail("");
          setPhoneNumber("");
          setMessage("");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setProcessing(false);
      toast.error("An error occured");
    }
  }
  return (
    <Container>
      <Helmet>
        <title>Contact Us - Festinekt</title>
        <meta
          name="description"
          content="Get in touch with us! Have questions or feedback? Use our Contact Us
        page to connect with our team. We're here to help and eager to hear from
        you!"
        />
      </Helmet>
      <Toaster />
      <Title>GET IN TOUCH</Title>
      <h4 className="text-gray-400 text-sm mt-2">
        Get in touch with us! Have questions or feedback? Use our Contact Us
        page to connect with our team. We're here to help and eager to hear from
        you!
      </h4>

      <form onSubmit={submit}>
        <Label myStyles="mt-8 mb-2">Full name*</Label>
        <TextInput
          placeholder="e.g John Doe"
          className="h-[50px] w-full"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
        <Label myStyles="mt-4 mb-2">Email*</Label>
        <TextInput
          placeholder="e.g johndoe@gmail.com"
          className="h-[50px] w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
        />
        <Label myStyles="mt-4 mb-2">Phone Number*</Label>
        <TextInput
          placeholder="e.g +254724753175"
          className="h-[50px] w-full"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <Label myStyles="mt-4 mb-2">Message*</Label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="..."
          className={`appearance-none rounded-lg h-[80px] w-full bg-gray-800 px-4 text-white text-[12px] border-0 focus:border-[1px] ring-0 focus:outline-none focus:border-gray-700`}
          required
        ></textarea>{" "}
        <br />
        <ReCAPTCHA
          className="mt-4"
          sitekey="6LdmmLIpAAAAAK9m4FrwcpxnxNM_p_uLBjpt9yOS"
          ref={captchaRef}
        />
        <PrimaryButton
          disabled={!fullName || !email || !phoneNumber || !message}
          processing={processing}
          className="w-full mt-4"
        >
          Submit
        </PrimaryButton>
      </form>

      <div className="mt-10 grid grid-cols-2 md:grid-cols-4 gap-4 text-myBlue">
        <a
          className="flex items-center gap-2"
          href="mailto:info@festinekt.com"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
            <path d="M22 6l-10 7L2 6" />
          </svg>
          info@festinekt.com
        </a>

        <a
          className="flex items-center gap-2"
          href="tel:+254742836404"
          target="_blank"
          rel="noreferrer"
        >
          <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
            <path d="M16.57 22a2 2 0 001.43-.59l2.71-2.71a1 1 0 000-1.41l-4-4a1 1 0 00-1.41 0l-1.6 1.59a7.55 7.55 0 01-3-1.59 7.62 7.62 0 01-1.59-3l1.59-1.6a1 1 0 000-1.41l-4-4a1 1 0 00-1.41 0L2.59 6A2 2 0 002 7.43 15.28 15.28 0 006.3 17.7 15.28 15.28 0 0016.57 22zM6 5.41L8.59 8 7.3 9.29a1 1 0 00-.3.91 10.12 10.12 0 002.3 4.5 10.08 10.08 0 004.5 2.3 1 1 0 00.91-.27L16 15.41 18.59 18l-2 2a13.28 13.28 0 01-8.87-3.71A13.28 13.28 0 014 7.41zM20 11h2a8.81 8.81 0 00-9-9v2a6.77 6.77 0 017 7z" />
            <path d="M13 8c2.1 0 3 .9 3 3h2c0-3.22-1.78-5-5-5z" />
          </svg>
          +254742836404
        </a>

        <a
          className="flex items-center gap-2"
          href="https://wa.me/254742836404"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            data-name="Layer 1"
            viewBox="0 0 24 24"
            fill="currentColor"
            height="1em"
            width="1em"
          >
            <path d="M21.99 6.547a10.59 10.59 0 00-.103-1.282 4.312 4.312 0 00-.363-1.09A3.853 3.853 0 0019.83 2.48a4.299 4.299 0 00-1.083-.362 10.523 10.523 0 00-1.292-.105c-.183-.007-.42-.01-.53-.01L7.077 2c-.11 0-.347.003-.53.01a10.565 10.565 0 00-1.282.103 4.312 4.312 0 00-1.09.363A3.854 3.854 0 002.48 4.17a4.303 4.303 0 00-.362 1.083 10.545 10.545 0 00-.106 1.292c-.006.183-.01.42-.01.53L2 16.923c0 .11.003.347.01.53a10.565 10.565 0 00.103 1.282 4.313 4.313 0 00.363 1.09A3.854 3.854 0 004.17 21.52a4.305 4.305 0 001.083.362 10.52 10.52 0 001.292.105c.183.007.42.01.53.01l9.848.002c.11 0 .347-.003.53-.01a10.578 10.578 0 001.282-.103 4.316 4.316 0 001.09-.363 3.854 3.854 0 001.696-1.694 4.301 4.301 0 00.362-1.083 10.533 10.533 0 00.106-1.292c.006-.183.01-.42.01-.53L22 7.077c0-.11-.003-.347-.01-.53zm-9.773 12.41h-.003a7.126 7.126 0 01-3.407-.868l-3.78.991 1.012-3.693a7.13 7.13 0 116.178 3.57z" />
            <path d="M12.22 5.901a5.927 5.927 0 00-5.023 9.076l.141.224-.599 2.186 2.243-.588.216.128a5.918 5.918 0 003.016.826h.003A5.926 5.926 0 0012.219 5.9zm3.484 8.47a1.834 1.834 0 01-1.202.847 2.443 2.443 0 01-1.122-.07 10.276 10.276 0 01-1.015-.376 7.94 7.94 0 01-3.043-2.689 3.463 3.463 0 01-.728-1.842 1.997 1.997 0 01.624-1.485.655.655 0 01.475-.223c.118 0 .237 0 .341.006.11.005.256-.042.4.306.15.356.506 1.233.55 1.322a.328.328 0 01.015.312 1.216 1.216 0 01-.178.297c-.09.104-.187.232-.267.312-.09.089-.182.185-.079.363a5.366 5.366 0 00.991 1.234 4.863 4.863 0 001.433.884c.178.09.282.074.386-.045s.445-.52.564-.698.237-.148.4-.089 1.04.49 1.218.58.297.133.341.207a1.488 1.488 0 01-.104.847z" />
          </svg>
          +254742836404
        </a>

        <a
          className="flex items-center gap-2"
          href="https://festinekt.com/"
          target="_blank"
          rel="noreferrer"
        >
          <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
            <path d="M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 019.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 015.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 00-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
          </svg>
          festinekt.com
        </a>
      </div>
    </Container>
  );
}
